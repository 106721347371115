import misotec from './images/Misotec.jpg'
import image1 from './images/Image1.png'
import avertiso from './images/avertiso.png'
import pronta from './images/pronta.jpg';
import backup from './images/backup.jpg'
import ptwo from './images/p2.jpg';
import trust from './images/trust.jpg'
import carousel1 from './images/carousel1.jpg'
import carousel2 from './images/carousel2.jpg'
import carousel3 from './images/carousel3.jpg'
import discount12 from './images/discount1.jpg'
import discount2 from './images/discount2.jpg'
import discount3 from './images/discount3.jpg'
import discount4 from './images/discount4.jpg'
import discount5 from './images/discount5.png'
import discount6 from './images/discount6.jpg'
import logo from './images/drugs_logo_5.png'
import logo6 from './images/drugs_logo_6.png'
import password from './images/password.png'
import google from './images/google.png'
import success from './images/success.png'
import medicine from './images/medicine.png'
import cardiology from './images/cardiology.png'
import cardio_condition from './images/cardio-condition.png'
import user from './images/user.png'
import vitamin from './images/vitamin.png'
import medical_insurance from './images/medical-insurance.png'
import twitter from './images/twitter.png';
import whatsapp from './images/whatsapp.png';
import instagram from './images/instagram.png';
import linkedin from './images/linkedin.png';
import youtube from './images/youtube.png';
import delivery from './images/delivery.png';
import tracking from './images/tracking.png';
import fast_delivery from './images/fast_delivery.png';
import whatsapp_big from './images/whatsapp_big.png';
import not_found from './images/not_found.png';
import face from './images/face.jpg'
import user1 from './images/user1.png'
import flash from './images/flash.png'
import image_gallery from './images/image_gallery.png'
import user_auth from './images/user_auth.png';
import heart from './images/heart.png';
import shopping_cart from './images/shopping_cart.png';
import skincare from './images/skincare.png'
import sick from './images/sick.png';
import nutrition_pyramid from './images/nutritional_pyramid.png';
import protein_shake from './images/protein_shake.png';
import protection from './images/protection.png';
import family from './images/family.png';
import settings from './images/settings.png';
import user_profile from './images/user_profile.png';
import approve from './images/approve.png';
import banner1 from './images/banner1.png';
import banner2 from './images/banner2.png';
import banner3 from './images/banner3.png';
import health_condition from './images/health_condition.webp';
import health_devices from './images/health_devices.webp';
import personale_care from './images/personal_care.webp';
import medicine_mob from './images/medicine_mob.webp';
import family_planning from './images/family_planning.jpg';
import vitamins_nutrition from './images/vitamins_nutrition.webp';
import pain from './images/pain.webp';
import wellness from './images/wellness.webp';
import add from './images/add.png';
import minus from './images/minus.png';
import adBanner from './images/adBanner.jpg';
import drugs from './images/drugs.png';
import fp from './images/fp.png';
import infographic from './images/allimages/infographic.png';
import NapInfo from './images/allimages/NapInfo.jpg';
import pointer from './images/pointer.png';
import offer1 from './images/offer1.png';
import offer2 from './images/offer2.jpg';
import offer3 from './images/offer3.jpg';
import allergy from './images/allergy.png';
import bloating from './images/bloating.png';
import antimalarials from './images/antimalarials.png';
import warning from './images/warning.png';
import condoms from './images/condoms.jpg';
import about from './images/about.jpg';
import value from './images/value.jpg';
import viya from './images/viya.png';
import marieStopes from './images/uganda.webp';
import nda from './images/nda.jpeg';
import moh from './images/moh.jpeg';
import ctadsk from './images/ctadsk.png';
import ctamob from './images/ctamob.png';
import ctaipd from './images/ctaipd.png';
import pay_hand from './images/pay-hand.png';
import momo from './images/momo.jpg';
import mtn from './images/mtn.png';
import airtel from './images/airtel.png';
import list from './images/list.png';
import scarousel1 from './images/carousel/scarousel1.webp'
import scarousel2 from './images/carousel/scarousel2.webp'
import scarousel3 from './images/carousel/scarousel3.webp'
import mcarousel1 from './images/carousel/mcarousel1.webp'
import mcarousel2 from './images/carousel/mcarousel2.webp'
import mcarousel3 from './images/carousel/mcarousel3.webp'
import lcarousel1 from './images/carousel/lcarousel1.webp'
import lcarousel2 from './images/carousel/lcarousel2.webp'
import lcarousel3 from './images/carousel/lcarousel3.webp'

const images = {
    misotec, image1, discount12, discount2, discount3, 
    discount4, discount5, discount6, pronta, trust, avertiso, backup, ptwo,
    logo, password, google, success, vitamin, medicine, medical_insurance,
    user, cardiology, cardio_condition, logo6, twitter, whatsapp,
    instagram, linkedin, youtube, delivery, tracking, fast_delivery, whatsapp_big,
    not_found, face, user1, flash, image_gallery, user_auth, heart, shopping_cart,
    sick, skincare, nutrition_pyramid, protein_shake, protection, family, settings,
    user_profile, approve, banner1, banner2, banner3, medicine_mob, personale_care,
    health_devices, health_condition, family_planning, vitamins_nutrition, pain,
    wellness, add, minus, adBanner, drugs, fp, infographic, NapInfo, pointer,
    offer1, offer2, offer3, allergy, bloating, antimalarials, warning, condoms,
    about, value, viya, marieStopes, nda, moh, carousel1, carousel2, carousel3,
    ctadsk, ctamob, ctaipd, pay_hand, momo, mtn, airtel, list, scarousel1, scarousel2,
    scarousel3, mcarousel1, mcarousel2, mcarousel3, lcarousel1, lcarousel2, lcarousel3
}

export default images;

