import React, {useState, useEffect} from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SearchBox, Pagination, Configure } from 'react-instantsearch';
import '../assets/css/search.css'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchProductCard from '../components/searchProductCard';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../components/productCard';
import BreadCrumb from '../components/breadCrumb';
import { getAllBanners } from '../store/banner_slice';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import { addCommas, offerImages } from '../utils.js';


const Hit = ({ hit }) => {
  return(
  <SearchProductCard
      id={hit.id}
      title={hit.name}
      gen_name={hit.generic_name}
      manufacturer={hit.manufacturer_name}
      price={addCommas(hit.b2c_price.toString())}
      category={hit.category_name}
      isFlashSale={hit.has_flash_sale}
      image={hit.images === undefined ? 'no_image' : hit.images[0]}
  />
)};


const Results = () => {
  return(
    <Hits hitComponent={Hit}/>
  )
}


function Search() {
  const [banners, setBanners] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  
  const fp = useSelector(state=>state.products.familyPlanningProducts)
  // const banners = useSelector(state=>state.banners.bannerList)
  // const searchClient = algoliasearch(process.env.REACT_APP_ALOGLIA_ONE, process.env.REACT_APP_ALOGLIA_TWO);
  const searchClient = algoliasearch('KNV1FMAL7M', 'd4cc4c916ab116c5e377895e94e27e3e');
  const queryHook = (query, search) => {
    const s = search(query);
  };
  const dispatch = useDispatch();

  useEffect(()=>{
    setIsLoading(true);
    dispatch(getAllBanners())
    .then(res=>{
      if(res['meta']['requestStatus'] === 'fulfilled'){
        
        setBanners(res.payload)
      }
    })
    .catch(error=> console.log())
    setIsLoading(false)
  },[dispatch])




  return (
    <>
      <BreadCrumb crumbs={[{text:'search', url:'/search'}]}/>
      <InstantSearch searchClient={searchClient} indexName="products">
          
            <div className='search-box-div'>
                <Configure hitsPerPage={10}/>
                <SearchBox 
                    classNames={{
                        input:'search-input',
                        // form:'search-box',
                        submitIcon:'submit-icon',
                        resetIcon:'reset-icon'
                    }}
                    submitIconComponent={({ classNames }) => (
                        <div className={classNames.submitIcon}><SearchOutlinedIcon/></div>
                    )}
                    resetIconComponent={({ classNames }) => (
                        <div><CloseOutlinedIcon fontSize='small'/>reset</div>
                    )}
                    placeholder='Search for medicines, brands ...'

                    queryHook={queryHook}
                />
            </div>  
            <div className='search-results'>
              <div className='search-results-div'>
                <div className='search-results-label'>
                  showing results...
                  <div className='search-spacer'></div>
                </div>
                <div className='results'>
                  <Results/>
                </div>
                <div className='search-results-pagination'>
                  <Pagination/>
                </div>
              </div>
              <div  className='search-recommendations'>
                <div className='search-offers'>
                  <div className='search-side-bar-title'>
                    <h5>Top Offers.</h5>
                    <NavLink>
                      view more
                    </NavLink>
                  </div>
                  <div className='search-side-offers'>
                    <Grid container spacing={2}>
                    {
                      isLoading || banners === null ?
                        <>
                          <Skeleton height={170} width={400} className='search-skeleton'/>
                          <Skeleton height={170} width={400} className='search-skeleton'/>
                          <Skeleton height={170} width={400} className='search-skeleton'/>
                        </>
                      :
                        offerImages.map((element, index)=>{
                          return(
                            <Grid item xs={12} md={12} lg={12}>
                              <NavLink>
                                <Paper elevation={2} className='search-offer-paper'>
                                  <img src={element} alt='' key={element.id}/>
                                </Paper>
                              </NavLink>
                            </Grid>

                          )
                        })
                    }
                    </Grid>
                  </div>
                </div>
                <div className='search-side-bar-title-featured'>
                  <h5>Featured Products</h5>
                </div>
                <div className='search-featured-products'>
                  <Grid container spacing={2}>
                    {
                      fp !== null ?
                        fp.map((product, index)=>{
                          return(
                            <Grid item xs={6} md={6}>
                              <ProductCard
                                id={product.id}
                                image={product.images.length > 0 ? product.images[0] : ''}
                                discount={product.discount}
                                title={product.name}
                                is_discounted={product.has_flash_sale}
                                newPrice={product.has_flash_sale ? product.flash_sale_b2c_price : ''}
                                originalPrice={product.b2c_price}
                                key={index}
                                description={product.description}
                              />
                            </Grid>
                          )
                        })
                      :
                        <Grid item xs={6} md={6}>
                          <Skeleton style={{height:'320px', width:'200px'}}/>
                          <Skeleton style={{height:'320px', width:'200px'}}/>
                          <Skeleton style={{height:'320px', width:'200px'}}/>
                          <Skeleton style={{height:'320px', width:'200px'}}/>
                          <Skeleton style={{height:'320px', width:'200px'}}/>
                          <Skeleton style={{height:'320px', width:'200px'}}/>
                        </Grid>
                    }
                  </Grid>
                </div>

              </div>
            </div>
          
      </InstantSearch>
    </>
  )
}


export default Search;