import React, {useEffect, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/pageBodyLayout.css'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBanners } from '../store/banner_slice';
import { offerImages } from '../utils';
import { Paper, Grid } from '@mui/material';
import ProductCard from '../components/productCard';
import Skeleton from 'react-loading-skeleton';
import BreadCrumb from '../components/breadCrumb';
import FeaturedProducts from '../components/featuredProducts';


function PageBodyLayout() {
    const dispatch = useDispatch();

    const fp = useSelector(state=>state.products.familyPlanningProducts)

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3, 
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay:true,
        // autoplaySpeed:1000,
    };


    useEffect(()=>{
    },[])

return (
    <div className='wrapper'>
        {/* <BreadCrumb/> */}
        <div className='main-div-area'>
            <main>
                <Outlet/>
            </main>
        </div>
        <div className='side-show'>
                <div>
                    <div className='side-show-title'><h3>Top Offers</h3></div>
                    <div className='offers-div'>                        
                        {
                            offerImages.map((element, index)=>{
                                return(
                                    <Paper elevation={2} className='side-show-paper'>
                                        <img src={element} alt='' key={index}/>
                                    </Paper>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='feature-products-div'>
                    <div className='side-show-featured-products-title'><h3>Featured Products</h3></div>
                    <div className='side-show-featured-products'>
                        <Slider {...settings} className='featuredP-slider'>
                            {
                                    fp !== null ?
                                        fp.map((product, index)=>{
                                        return(
                                                <FeaturedProducts
                                                    image={product.images.length > 0 ? product.images[0] : ''}
                                                    title={product.name}
                                                    id={product.id}
                                                />
                                        )
                                        })
                                :
                                    <Grid item xs={6} md={6}>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                    </Grid>
                            }
                        </Slider>
                    </div>
                </div>
        </div>
    </div>
)
}

export default PageBodyLayout