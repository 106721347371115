import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Checkbox, 
  FormControl, 
  Select, 
  MenuItem,
  InputLabel
} from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/familyPlanStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../components/productCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { filterProducts, getFamilyPlanningAsync } from '../store/products_slice.js';
import images from '../assets/index.js';
import BreadCrumb from '../components/breadCrumb.js';
import TuneIcon from '@mui/icons-material/Tune';
import Skeleton from 'react-loading-skeleton';
import {capitalizeProductName} from '../utils.js'


// Products Tile Grid Component
const ProductGrid = ({ products, title }) => (
  <div className='product-grid-div'>  

    <Grid container spacing={2}>
      {products.map((product, index) => (
        <Grid item key={index} xs={6} sm={3} md={3} lg={3} style={{justifyContent:'center', display:'flex'}}>
          <ProductCard
            id={product.id}
            image={product.images.length > 0 ? product.images[0] : ''}
            discount={product.discount}
            title={product.name}
            is_discounted={product.has_flash_sale}
            newPrice={product.has_flash_sale ? product.flash_sale_b2c_price : ''}
            originalPrice={product.b2c_price}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

const ProductGridSkeleton = () => {
  const element = []
  for (let i = 0; i < 12; i++){
    element.push(<Skeleton style={{height:'320px', width:'180px'}}/>)
  }
  return(
  <div style={{ marginBottom: '30px', position:'relative', marginTop:'20px' }}>  

    <Grid container spacing={2}>
      {element.map((element, index) => (
        <Grid item key={index} xs={6} sm={3} md={3} lg={3}>
          {element}
        </Grid>
      ))}
    </Grid>
  </div>
)};

const FamilyPlan = () => {
  const fp = useSelector(state=> {
    if(state.product !== null && state.product !== undefined){
      return state.product.familyPlanningProducts;
    }
    else{
      return [];
    }
  })
  const [filterTerm, setFilterTerm] = useState(null);
  const [brandTerm, setBrandTerm] = useState(null);
  const [fpProducts, setFpProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false);
  const [maleChecked, setMaleChecked] = useState(false)
  const [femaleChecked, setFemaleChecked] = useState(false)
  const dispatch = useDispatch()


  useEffect(()=>{
    const pageSize = 20
    const page = 1
    const lastVisibleDoc = null;
    setIsLoading(true);
    
    dispatch(getFamilyPlanningAsync({page, pageSize, lastVisibleDoc}))
    .then(res=>{
      if(res['meta']['requestStatus'] === 'fulfilled'){
        let brandsArray = [];
        setFpProducts(res.payload.familyPlanningProductsList);
        for(let i = 0; i < res.payload.familyPlanningProductsList.length; i++){
          if(!brandsArray.includes(res.payload.familyPlanningProductsList[i].manufacturer_name)){
            brandsArray.push(res.payload.familyPlanningProductsList[i].manufacturer_name)
          } 
        }
        setBrands(brandsArray);
        setIsLoading(false);
      }
    })

    if(maleChecked){
      dispatch(filterProducts({gender:'male'}))
      .then(res=>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setFpProducts(res.payload)
          setFilterTerm('male')
        }
        setIsLoading(false);
      })
    }

    if(femaleChecked){
      dispatch(filterProducts({gender:'female'}))
      .then(res=>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setFpProducts(res.payload)
          setFilterTerm('female')
        }
        setIsLoading(false);
      })
    }

    if(brandTerm !== null){
      setIsLoading(true);
      dispatch(filterProducts({brand:brandTerm}))
      .then(res=>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setFpProducts(res.payload)
        }
        setIsLoading(false);
      })
    }


    setIsLoading(false);
    
  },[filterTerm, brandTerm, maleChecked, femaleChecked])

  const handleDelete = ()=>{
    setFilterTerm(null)
  }

  const handleBrandTermDelete = ()=>{
    setBrandTerm(null)
  }

  const handleMaleChange = ()=>{
    setMaleChecked(!maleChecked)
  }

  const handleFemaleChange = ()=>{

    setFemaleChecked(!femaleChecked)
  }

  const onBrandChange = (event)=>{
    
    setBrandTerm(event.target.value)
  }

  return (
    <>
      <div className='fp-filter'>
        <IconButton onClick={()=>setShow(!show)}>
          <TuneIcon className='fp-filter-icon' fontSize='large'/>
          </IconButton>
        <div className={show ? "filter-options-open" : "filter-options-closed"}>
          <div className='filter-text'>
            Filter.
          </div>
          <div>
            <Checkbox
              checked={maleChecked}
              onChange={handleMaleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            Male
          </div>
          <div>
            <Checkbox
              checked={femaleChecked}
              onChange={handleFemaleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            Female
          </div>
          <div className='filter-brand'>
            <FormControl fullWidth>
              {/* <InputLabel id="brand-select-label">Brand</InputLabel> */}
              <Select
                labelId='brand-select-label'
                id='brand-select'
                value={brandTerm}
                label="Brand"
                onChange={onBrandChange}
              >
                {
                  brands.map((item, index)=>{
                    return(
                      <MenuItem
                        className='family-planning-option'
                        key={index} 
                        value={item}                      
                      >
                          {capitalizeProductName(item)}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className='family-planning-carousel'>
        <BreadCrumb crumbs={[
          {text:'family planning', url:'/family-planning'}
        ]}/>
        <img src={images.infographic} alt='' style={{width:'100%', height:'500px', objectFit:'cover'}}/>
      </div>
      <div className='family-planning-wrapper'>
        {/* <div className='family-planning-filter'>
          <div className='family-planning-filter-label'>
          <FilterListOutlinedIcon/>  <h5>Apply Filter</h5>
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='p'>GENDER</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p 
                className='family-planning-option'
                onClick={()=>{
                  setFilterTerm('male')
                }}
              >
                Male
              </p>
              <p
                className='family-planning-option'
                onClick={()=>{
                  setFilterTerm('female')
                }}
              >
                Female
              </p>
              <p
                className='family-planning-option'
                onClick={()=>{
                  setFilterTerm('unisex')
                }}
              >
                Unisex
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
          `
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>BRANDS</AccordionSummary>
            <AccordionDetails>
              {
                brands.map((item, index)=>{
                  return(
                    <p
                      className='family-planning-option'
                      key={index}
                      onClick={()=>{
                        setBrandTerm(item)
                      }}>
                        {capitalizeProductName(item)}
                    </p>
                  )
                })
              }
            </AccordionDetails>
          </Accordion>
        </div> */}

        {/* <div className='family-planning-filter-mob'>
          {/* <div onClick={()=> setShow(!show)} className='family-planning-filter-mob-label'>
            <FilterListOutlinedIcon/> Apply Filter
          </div> */}
          
          {/* <div className={show ? 'family-planning-filter-results-mob-show' : 'family-planning-filter-results-mob-hidden'}>
            <Accordion className='family-planning-filter-mob-accordion'>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='p'>GENDER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p 
                  className='family-planning-option'
                  onClick={()=>{
                    setFilterTerm('male')
                  }}
                >
                  Male
                </p>
                <p
                  className='family-planning-option'
                  onClick={()=>{
                    setFilterTerm('female')
                  }}
                >
                  Female
                </p>
                <p
                  className='family-planning-option'
                  onClick={()=>{
                    setFilterTerm('unisex')
                  }}
                >
                  Unisex
                </p>
              </AccordionDetails>
            </Accordion >
            <Accordion className='family-planning-filter-mob-accordion'>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>BRANDS</AccordionSummary>
              <AccordionDetails>
                {
                  brands.map((item, index)=>{
                    return(
                      <p
                        className='family-planning-option'
                        key={index}
                        onClick={()=>{
                          setBrandTerm(item)
                        }}>
                          {capitalizeProductName(item)}
                      </p>
                    )
                  })
                }
              </AccordionDetails>
            </Accordion>
          </div> 
        </div> */}

        <div className='family-planning-div'>
          {
            filterTerm !== null || brandTerm !== null?
              <>
                <div className='family-planning-filter-div'>
                  Showing filtered results for:
                </div>
                <div className='filter-chips'>
                  {filterTerm !== null ? <Chip label={filterTerm} onDelete={handleDelete} variant='outlined'/> : <></>}
                  {brandTerm !== null ? <Chip label={brandTerm} onDelete={handleBrandTermDelete} variant='outlined'/> : <></>}
                </div>
              </>
            :
              <></>
          }
          {
            isLoading || fpProducts.length <= 0 ?
              <ProductGridSkeleton/>
            :
              <ProductGrid products={fpProducts} title="Family Planning Products" />
          }
        </div>
      </div>
    </>
  );
};

export default FamilyPlan;