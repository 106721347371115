import React from 'react'
import { Paper, Button } from '@mui/material'
import images from '../assets/index.js'
import '../assets/css/components.css'
import { NavLink } from 'react-router-dom'
import { capitalizeProductName } from '../utils.js'

function SearchProductCard(props) {
  return (
    <NavLink
        to={`/${props.title}/${props.id}/details`}
        className='search-product-link'
    >    
        <Paper className='search-product-paper' elevation={1}>
            <div className='search-product-img'>
                {
                    props.image === 'no_image' ?
                        <img src={images.drugs} alt='' style={{width:'70px', height:'70px'}}/>
                    :
                        <img src={props.image} alt='' style={{width:'70px', height:'70px'}}/>
                }
                {/* <img src={images.drugs} alt='' style={{width:'70px', height:'70px'}}/> */}
            </div>
            <div className='search-product-desc'>
                <div className='search-product-data'>
                    <div className='search-product-title'> 
                        <h3>{capitalizeProductName(props.title, ' ')}</h3>
                    </div>
                    <p>by {props.manufacturer}</p>
                    <p>{props.discount_price}</p>
                    <h5>{props.price} Ugx.</h5>
                    <div className='search-product-category'>
                        <p>Category: {props.category}</p>
                    </div>
                </div>
                <div className='search-product-icons'>
                    {
                        props.isFlashSale ?
                            <Button variant='outlined'>
                                <img src={images.flash} alt=''/>
                                Flash Sale
                            </Button>
                            
                        :
                            ''
                    }
                </div>
            </div>
            {/* <div className='search-product-tap'>
                Tap to view
                <img alt='' src={images.pointer}/>
            </div> */}
        </Paper>
    </NavLink>
  )
}

export default SearchProductCard