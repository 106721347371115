import React from 'react'
import '../assets/css/components.css'
import images from '../assets'

function Banner() {
  return (
    <div className='banner-div'>
        <img src={images.ctadsk} alt='' className='ctadsk'/>
        <img src={images.ctamob} alt='' className='ctamob'/>
        <img src={images.ctaipd} alt='' className='ctaipd'/>
    </div>
  )
}

export default Banner