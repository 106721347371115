import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { getAllBanners } from '../store/banner_slice';
import { Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import '../assets/css/offers.css';
import { offerImages } from '../utils';
import BreadCrumb from '../components/breadCrumb';


function Offers() {
    const dispatch = useDispatch()
    const [offers, setOffers] = useState(null);

    useEffect(()=>{
        dispatch(getAllBanners())
        .then(res => {
            setOffers(res.payload)
        })
        .catch(error=>console.log())
    }, [])

    return (
        <div className='offers-div'>
            <BreadCrumb
                crumbs={[
                {text:'Offers', url:'/offers'}
            ]}/>

            <div className='offers-wrapper'>
                {
                    offerImages.map((element, index)=>{
                        return(
                            <NavLink>
                                <Paper elevation={3} className='offer-paper'>
                                    <img src={element} alt=''/>
                                </Paper>
                            </NavLink>
                        )
                    })

                }
                {
                    offers && offers.map((element, index)=>{

                        return(
                            <NavLink>
                                <Paper elevation={3} className='offer-paper'>
                                    <img src={element.image} alt=''/>
                                </Paper>
                            </NavLink>
                        )
                    })
                }
            </div>
        </div>
  )
}

export default Offers