import { Container, Grid } from '@mui/material'
import React from 'react'
import { credits } from '../utils'

function Credits() {
  return (
    <Container style={{ marginTop:"20px", backgroundColor:''}}>
        <Grid container spacing={2}>
                {
                    credits.map((item, index)=>{
                        return(
                            <Grid xs={6} key={index}>
                                <div>
                                    <a href={item.url}>
                                        {item.title}
                                    </a>
                                </div>
                            </Grid>
                        );
                    })
                }
        </Grid>
    </Container>
  )
}

export default Credits